import * as React from "react";
import * as RS from "rsuite";
import { Stylist, StylistService, StylistTestimonial } from "../types/stylist";
import { SmallMessage } from "./Message";
import { saveStylist } from "../services/stylists-service";
import { TagInput, TextArea, TextField } from "./TextField";
import { v4 as uuid } from "uuid";
import Uploader from "./Uploader";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export interface StylistFormProps {
  stylist?: Stylist;
  display: number;
  onSaved?: () => void;
}

const { StringType, ArrayType, ObjectType } = RS.Schema.Types;

// Need to inject `display` & `stylistId` before save of current stylist
const stylistModel = RS.Schema.Model({
  about: StringType().isRequired(), // good
  avaliability: ArrayType().of(StringType()), // good
  contact: ObjectType().shape({
    email: StringType().isEmail(),
    instagram1: StringType(),
    instagram2: StringType(),
    phone: StringType(),
  }), // good
  sname: StringType(), // good
  services: ArrayType().of(
    ObjectType().shape({
      name: StringType(),
      description: StringType(),
      price: StringType(),
    })
  ),
  specialized: ArrayType().of(StringType()), // good
  additional_info: StringType(), // good
  advanced: ArrayType().of(StringType()), // good
  bio: StringType(), // good
  testimonials: ArrayType().of(
    ObjectType().shape({
      name: StringType(),
      body: StringType(),
    })
  ),
});

const serviceModel = RS.Schema.Model({
  sname: StringType(),
  description: StringType(),
  price: StringType(),
});

const testimonialModel = RS.Schema.Model({
  sname: StringType(),
  body: StringType(),
});

const StylistForm: React.FC<StylistFormProps> = ({
  stylist,
  display,
  onSaved,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [formStylist, setFormStylist] = React.useState<Stylist>();
  const [openAddService, setOpenAddService] = React.useState(false);
  const [openAddTestimonial, setOpenAddTestimonial] = React.useState(false);
  const [newService, setNewService] = React.useState<StylistService>({
    id: uuid(),
    description: "",
    name: "",
    price: "",
  });
  const [newTestimonial, setNewTestimonial] =
    React.useState<StylistTestimonial>({
      id: uuid(),
      body: "",
      name: "",
    });
  const toaster = RS.useToaster();

  const createNew = React.useCallback(
    (): Stylist => ({
      about: "",
      avaliability: [],
      contact: { email: "", instagram1: "", instagram2: "", phone: "" },
      display,
      name: "",
      services: [],
      specialized: [],
      stylistId: "-1",
      additional_info: "",
      advanced: [],
      bio: "",
      testimonials: [],
    }),
    [display]
  );

  React.useEffect(() => {
    if (!stylist) setFormStylist(createNew());
    else {
      setFormStylist({ ...stylist, sname: stylist.name } as Stylist);
    }
  }, [stylist, createNew]);

  const onSubmit = async (valid: boolean) => {
    setLoading(true);
    var toSaveStylist: Stylist;
    if (formStylist?.stylistId === "-1") toSaveStylist = formStylist;
    else
      toSaveStylist = {
        ...formStylist,
        stylistId: stylist!.stylistId,
        display: stylist!.display,
      } as Stylist;
    if ((toSaveStylist as any).sname) {
      toSaveStylist.name = (toSaveStylist as any).sname;
      delete (toSaveStylist as any).sname;
    }
    const saved = await saveStylist(toSaveStylist);
    if (saved) toaster.push(<SmallMessage message="Saved" type="success" />);
    else toaster.push(<SmallMessage message="Failed to save" type="error" />);
    setLoading(false);
    onSaved?.();
  };

  const onAddNewTstimonial = (valid: boolean) => {
    if (!valid)
      return toaster.push(
        <SmallMessage message="Form is not valid" type="error" />
      );
    var toAddTestimonial: StylistTestimonial = newTestimonial;
    toAddTestimonial.name = (toAddTestimonial as any).sname;
    delete (toAddTestimonial as any).sname;
    setFormStylist({
      ...formStylist,
      testimonials: [...(formStylist!.testimonials || []), newTestimonial],
    } as Stylist);
    setNewTestimonial({
      id: uuid(),
      body: "",
      name: "",
    });
    setOpenAddTestimonial(false);
  };

  const onAddNewService = (valid: boolean) => {
    if (!valid)
      return toaster.push(
        <SmallMessage message="Form is not valid" type="error" />
      );
    var toAddService: StylistService = newService;
    toAddService.name = (toAddService as any).sname;
    delete (toAddService as any).sname;
    setFormStylist({
      ...formStylist,
      services: [...(formStylist!.services || []), newService],
    } as Stylist);
    setNewService({
      id: uuid(),
      description: "",
      name: "",
      price: "",
    });
    setOpenAddService(false);
  };

  return (
    <React.Fragment>
      <RS.Form
        fluid
        model={stylistModel}
        onSubmit={onSubmit}
        formValue={formStylist}
        onChange={(v) => setFormStylist(v as Stylist)}
      >
        {formStylist?.stylistId === "-1" ? (
          <p>
            <i>
              Please note that if you are creating a new stylist you cannot
              upload an image first. You must create the stylist and edit them
              to add thier image.
            </i>
          </p>
        ) : (
          <Uploader
            bucketKey={`stylists/${stylist?.stylistId}`}
            buttonText="Upload Stylist Image"
            draggable
          />
        )}
        <TextField name="sname" label="*Name" placeholder="Stylist name..." />
        <TextArea
          name="about"
          label="*About"
          placeholder="About the stylist..."
        />
        <TextArea
          name="bio"
          label="Bio (optional)"
          placeholder="Stylist bio..."
        />
        <TextArea
          name="additional_info"
          label="Additional Info (optional)"
          placeholder="Any additional info about the stylist"
        />
        <TagInput
          name="avaliability"
          label="Avaliability (can add multiple, optional)"
        />
        <TagInput
          name="specialized"
          label="Specializes in (can add multiple, optional)"
        />
        <TagInput
          name="advanced"
          label="Advanced in (can add multiple, optional)"
        />
        <TextField
          onChange={(email) =>
            setFormStylist({
              ...formStylist,
              contact: { ...formStylist?.contact, email },
            } as Stylist)
          }
          value={formStylist?.contact.email || ""}
          label="Contact email (optional)"
          placeholder="example@example.com"
        />
        <TextField
          onChange={(v) =>
            setFormStylist({
              ...formStylist,
              contact: {
                ...formStylist?.contact,
                instagram1: v.replace("@", ""),
              },
            } as Stylist)
          }
          value={formStylist?.contact.instagram1 || ""}
          label="Instagram Username 1 (optional)"
          placeholder="instagram username..."
        />
        <TextField
          onChange={(v) =>
            setFormStylist({
              ...formStylist,
              contact: {
                ...formStylist?.contact,
                instagram2: v.replace("@", ""),
              },
            } as Stylist)
          }
          value={formStylist?.contact.instagram2 || ""}
          label="Instagram Username 2 (optional)"
          placeholder="Instagram username..."
        />
        <TextField
          onChange={(v) =>
            setFormStylist({
              ...formStylist,
              contact: {
                ...formStylist?.contact,
                phone: v,
              },
            } as Stylist)
          }
          value={formStylist?.contact.phone || ""}
          label="Phone # (optional)"
          placeholder="Stylist phone number..."
        />
        <TextField
          onChange={(v) =>
            setFormStylist({
              ...formStylist,
              contact: {
                ...formStylist?.contact,
                website: v,
              },
            } as Stylist)
          }
          value={formStylist?.contact.website || ""}
          label="Website (optional)"
          placeholder="Stylist website..."
        />
        <p>Services</p>
        <RS.Button
          appearance="ghost"
          onClick={() => setOpenAddService(true)}
          style={{ marginBottom: 5 }}
        >
          Add Service
        </RS.Button>
        <div style={{ marginBottom: 20 }}>
          {formStylist?.services.map((service, i) => (
            <RS.Tag
              key={i}
              style={{ margin: 3 }}
              closable
              onClose={() =>
                setFormStylist({
                  ...formStylist,
                  services: formStylist.services.filter(
                    (x) => x.id !== service.id
                  ),
                })
              }
            >
              <b>{service.name}</b>
              <br />
              {service.description}
              <br />
              {formatter.format(parseFloat(service.price + ""))}
            </RS.Tag>
          ))}
          {!formStylist?.services.length && <p>No Services</p>}
        </div>
        <p>Testimonials</p>
        <RS.Button
          appearance="ghost"
          onClick={() => setOpenAddTestimonial(true)}
          style={{ marginBottom: 5 }}
        >
          Add Testimonial
        </RS.Button>
        <div style={{ marginBottom: 20 }}>
          {(formStylist?.testimonials || []).map((testimonial, i) => (
            <RS.Tag
              key={i}
              style={{ margin: 3 }}
              closable
              onClose={() =>
                setFormStylist({
                  ...formStylist,
                  testimonials: (formStylist?.testimonials || []).filter(
                    (x) => x.id !== testimonial.id
                  ),
                } as Stylist)
              }
            >
              <b>{testimonial.name}</b>
              <br />
              {testimonial.body}
            </RS.Tag>
          ))}
          {!formStylist?.testimonials?.length && <p>No Testimonials</p>}
        </div>
        <RS.ButtonToolbar>
          <RS.Button
            appearance="primary"
            type="submit"
            loading={loading}
            disabled={loading}
            style={{ marginBottom: 50 }}
          >
            Save
          </RS.Button>
        </RS.ButtonToolbar>
      </RS.Form>
      <RS.Modal open={openAddService} onClose={() => setOpenAddService(false)}>
        <RS.Modal.Header>
          <RS.Modal.Title>Add New Stylist Service:</RS.Modal.Title>
        </RS.Modal.Header>
        <RS.Modal.Body>
          <RS.Form
            fluid
            model={serviceModel}
            onSubmit={onAddNewService}
            formValue={newService}
            onChange={(v) => setNewService(v as StylistService)}
          >
            <TextField
              name="sname"
              label="Service Name"
              placeholder="service name..."
            />
            <TextField
              name="description"
              label="Description"
              placeholder="service description..."
            />
            <TextField
              name="price"
              label="Service Price"
              placeholder="service price..."
            />
            <RS.ButtonToolbar>
              <RS.Button appearance="primary" type="submit">
                Add
              </RS.Button>
            </RS.ButtonToolbar>
          </RS.Form>
        </RS.Modal.Body>
      </RS.Modal>
      <RS.Modal
        open={openAddTestimonial}
        onClose={() => setOpenAddTestimonial(false)}
      >
        <RS.Modal.Header>
          <RS.Modal.Title>Add New Stylist Testimonial:</RS.Modal.Title>
        </RS.Modal.Header>
        <RS.Modal.Body>
          <RS.Form
            fluid
            model={testimonialModel}
            onSubmit={onAddNewTstimonial}
            formValue={newTestimonial}
            onChange={(v) => setNewTestimonial(v as StylistTestimonial)}
          >
            <TextField
              name="sname"
              label="Client Name"
              placeholder="name of person giving testimonial..."
            />
            <TextArea
              name="body"
              label="Testimonial"
              placeholder="testimonial body..."
            />

            <RS.ButtonToolbar>
              <RS.Button appearance="primary" type="submit">
                Add
              </RS.Button>
            </RS.ButtonToolbar>
          </RS.Form>
        </RS.Modal.Body>
      </RS.Modal>
    </React.Fragment>
  );
};

export default StylistForm;
