import { $http } from "../axios";

export const getProductKeys = async (): Promise<string[]> => {
  try {
    const response = await $http.get("/product/keys");
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
