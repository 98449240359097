import * as React from "react";
import * as RS from "rsuite";

export const SmallMessage = React.forwardRef<
  any,
  RS.MessageProps & { message: string }
>(({ type, message, ...rest }, ref) => {
  return (
    <RS.Message
      ref={ref}
      {...rest}
      type={type}
      style={{
        background:
          type === "error"
            ? "red"
            : type === "info"
            ? "blue"
            : type === "success"
            ? "green"
            : type === "warning"
            ? "orange"
            : "black",
      }}
    >
      <h5 style={{ color: "white" }}>{message}</h5>
    </RS.Message>
  );
});
