import * as React from "react";
import Template from "../components/Template";
import { useParams, Navigate } from "react-router-dom";
import { Stylist } from "../types/stylist";
import { getStylistById } from "../services/stylists-service";
import Loader from "../components/Loader";
import "../styles/stylist.css";
import { getStylistGalleryKeys } from "../services/gallery-service";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const StylistPage: React.FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [stylist, setStylist] = React.useState<Stylist | null>(null);
  const [galleryKeys, setGalleryKeys] = React.useState<string[]>([]);
  const [galleryOpen, setGalleryOpen] = React.useState(true);
  const [testimonialsOpen, setTesimonialsOpen] = React.useState(false);

  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  React.useEffect(() => {
    if (id)
      getStylistById(id)
        .then((_stylist) =>
          getStylistGalleryKeys(_stylist?.stylistId || "")
            .then((keys) => {
              setStylist(_stylist);
              setGalleryKeys(keys);
            })
            .catch(console.log)
            .finally(() => setLoading(false))
        )
        .catch(console.log);
  }, [id]);

  if (loading)
    return (
      <Template>
        <Loader />
      </Template>
    );

  if (!stylist) return <Navigate to="/not-found" />;

  return (
    <Template>
      <h1 id="name">{stylist.name}</h1>
      <div className="stylist-info-container">
        <img
          src={`${process.env.REACT_APP_S3_DOMAIN}/stylists/${
            stylist.stylistId
          }?_v=${Math.random().toString(36).slice(2)}`}
          alt="stylist"
          className="stylist-info-img"
        />
        <div className="stylist-info-details">
          {stylist.bio && <p>{stylist.bio}</p>}
          {stylist.specialized.length && (
            <React.Fragment>
              <h2>Specializing In:</h2>
              <ul>
                {stylist.specialized.map((s, i) => (
                  <li key={i}>{s}</li>
                ))}
              </ul>
            </React.Fragment>
          )}
          {(stylist.advanced?.length || 0) > 0 && (
            <React.Fragment>
              <h2>Advanced Training:</h2>
              <ul>
                {stylist.advanced!.map((a, i) => (
                  <li key={i}>{a}</li>
                ))}
              </ul>
            </React.Fragment>
          )}
          <h2>About {stylist.name}:</h2>
          <p>{stylist.about}</p>
          <h2>Services:</h2>
          <div className="stylist-info-services">
            {stylist.services.map((service, i) => (
              <div key={i} className="service-card">
                <h3>{service.name}</h3>
                <div className="card-spacer" />
                <p>{service.description}</p>
                <h2>
                  {service.price
                    ? formatter.format(parseFloat(service.price + ""))
                    : ""}
                </h2>
              </div>
            ))}
          </div>
          <h2>Schedule an Appointment:</h2>
          <p>
            <strong>Avaliability:</strong> {stylist.avaliability.join(", ")}
          </p>
          {stylist.additional_info && (
            <p>
              <i>
                <b>***</b>
                {stylist.additional_info}
              </i>
            </p>
          )}
          <p className="un-margin-p">
            <strong>My Contacts:</strong>
          </p>
          <ul className="un-margin-ul">
            {stylist.name === "Amber Shinn" ||
            stylist.name === "Kim Cordova" ? (
              ""
            ) : (
              <li>
                <a href="tel:7208517799">720-851-7799</a> | Salon
              </li>
            )}
            {stylist.contact.phone && (
              <li>
                <a href={`tel:${stylist.contact.phone}`}>
                  {stylist.contact.phone}
                </a>
                &nbsp;|&nbsp;Cell
              </li>
            )}
            {stylist.contact.website && (
              <li>
                <a
                  href={`https://${stylist.contact.website.replace(
                    /http(s)?:\/\//,
                    ""
                  )}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {stylist.contact.website.replace(/http(s)?:\/\//, "")}
                </a>
                &nbsp;|&nbsp;Website
              </li>
            )}
            {stylist.contact.email && (
              <li>
                <a
                  href={`mailto:${stylist.contact.email}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {stylist.contact.email}
                </a>
                &nbsp;|&nbsp;Email
              </li>
            )}
            {stylist.contact.instagram1 && (
              <li>
                <a
                  href={`https://www.instagram.com/${stylist.contact.instagram1}/`}
                  rel="noreferrer"
                  target="_blank"
                >
                  @{stylist.contact.instagram1}
                </a>
                &nbsp;|&nbsp;Instagram
              </li>
            )}
            {stylist.contact.instagram2 && (
              <li>
                <a
                  href={`https://www.instagram.com/${stylist.contact.instagram2}/`}
                  rel="noreferrer"
                  target="_blank"
                >
                  @{stylist.contact.instagram2}
                </a>
                &nbsp;|&nbsp;Instagram
              </li>
            )}
          </ul>
          <div className="tab-container" id="stylist-tabs">
            <div className="tab">
              <button
                className={`tablinks ${galleryOpen ? "active" : ""}`}
                onClick={() => {
                  setGalleryOpen(true);
                  setTesimonialsOpen(false);
                }}
              >
                Gallery
              </button>
              <button
                className={`tablinks ${testimonialsOpen ? "active" : ""}`}
                onClick={() => {
                  setTesimonialsOpen(true);
                  setGalleryOpen(false);
                }}
              >
                Testimonials
              </button>
            </div>
            <div
              className="tabcontent"
              style={{
                display: galleryOpen ? "block" : "none",
              }}
            >
              <div className="gallery-tab-container">
                {galleryKeys.length ? (
                  galleryKeys.map((key, i) => (
                    <a
                      href={`${process.env.REACT_APP_S3_DOMAIN}/${key}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="gallery-tab-card"
                        alt="stylist gallery"
                        src={`${process.env.REACT_APP_S3_DOMAIN}/${key}`}
                      />
                    </a>
                  ))
                ) : (
                  <h2>No Content</h2>
                )}
              </div>
            </div>
            <div
              className="tabcontent"
              style={{
                display: testimonialsOpen ? "block" : "none",
              }}
            >
              <div className="gallery-tab-container">
                {stylist.testimonials?.length ? (
                  stylist.testimonials.map((t, i) => (
                    <div key={i} className="testimonial-tab-card">
                      <h3>{t.name}</h3>
                      <p>{t.body}</p>
                    </div>
                  ))
                ) : (
                  <h2>No Content</h2>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default StylistPage;
