import * as React from "react";
import * as RS from "rsuite";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const textarea = React.forwardRef((props, ref) => (
  <RS.Input {...props} as="textarea" ref={ref as any} />
));

export interface TextFieldProps extends RS.InputProps {
  name?: string;
  label: string | React.ReactElement;
  accepter?: any;
}

export const TextField: React.FC<TextFieldProps> = (props) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <RS.Form.Group controlId={name} style={props.style}>
      <RS.Form.ControlLabel>{label}</RS.Form.ControlLabel>
      <RS.Form.Control name={name} accepter={accepter} {...rest} />
    </RS.Form.Group>
  );
};

export const TextArea: React.FC<TextFieldProps & { placeholder?: string }> = (
  props
) => {
  const { name, label, placeholder } = props;
  return (
    <RS.Form.Group controlId={name} style={props.style}>
      <RS.Form.ControlLabel>{label}</RS.Form.ControlLabel>
      <RS.Form.Control
        name={name || ""}
        accepter={textarea}
        placeholder={placeholder}
      />
    </RS.Form.Group>
  );
};

export const TagInput: React.FC<TextFieldProps> = (props) => {
  const { name, label } = props;
  return (
    <RS.Form.Group controlId={name} style={props.style}>
      <RS.Form.ControlLabel>{label}</RS.Form.ControlLabel>
      <RS.Form.Control
        name={name || ""}
        accepter={RS.TagInput}
        style={{ width: "100%" }}
        trigger="Enter"
      />
    </RS.Form.Group>
  );
};

export const PasswordTextField: React.FC<TextFieldProps> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const { name, label, accepter, ...rest } = props;
  return (
    <RS.Form.Group controlId={name}>
      <RS.Form.ControlLabel>{label}</RS.Form.ControlLabel>
      <RS.InputGroup>
        <RS.Form.Control
          name={name}
          accepter={accepter}
          {...rest}
          type={visible ? "text" : "password"}
        />
        <RS.InputGroup.Button onClick={() => setVisible(!visible)}>
          {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </RS.InputGroup.Button>
      </RS.InputGroup>
    </RS.Form.Group>
  );
};
