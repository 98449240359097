import * as React from "react";
import Template from "../components/Template";
import { Link } from "react-router-dom";
import "../styles/services.css";

const Services: React.FC = () => {
  return (
    <Template>
      <h1 id="services-title">Our Services</h1>
      <div className="services-main-container">
        <h2>
          <span className="discover">
            <i>Discover.</i>
          </span>{" "}
          Your style, our expertise.
          <br />
          It all begins here.
        </h2>
        <p>
          Achieve your unique style. We offer a variety of hair and salon
          services skillfully provided by top stylists. If you need more details
          about our services, please give us a call at
          <a href="tel:7208517799">720-851-7799</a> or
        </p>
        <Link to="/stylists">
          <div className="stylist-btn">
            Click Here to View Stylists & Prices
          </div>
        </Link>
        <p>Services we offer:</p>
        <div className="services-grid">
          <div className="service-card-main">
            <div className="service-card cut"></div>
            <h2>Cut & Style</h2>
            <p className="card-details">
              Men, Women and Children's cuts that compliment your look
            </p>
          </div>

          <div className="service-card-main">
            <div className="service-card color"></div>
            <h2>Color</h2>
            <p className="card-details">
              Create unique Highlights, Balayage, and Dimensional Color
            </p>
          </div>

          <div className="service-card-main">
            <div className="service-card specialty-services"></div>
            <h2>Specialty Services</h2>
            <p className="card-details">
              Nails, Waxing, Brazilian Blowout, and Perms
            </p>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Services;
