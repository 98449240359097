import * as React from "react";
import Template from "../components/Template";
import { Link } from "react-router-dom";
import PATTY_TESTEMONIAL from "../images/testimonials/Patty.jpg";
import LOIS_TESTIMONIAL from "../images/testimonials/Lois.jpg";
import BLAKE_TESTIMONIAL from "../images/testimonials/Blake.png";
import "../styles/home.css";

const Home: React.FC = () => {
  return (
    <Template>
      <div className="home-main">
        <div className="greeting-container">
          <div className="logo-container">
            <h1 className="logo">Wild Iris Salon Experience</h1>
            <h2 className="byokof">
              Be&nbsp;&nbsp;Your&nbsp;&nbsp;Own&nbsp;&nbsp;Kind&nbsp;&nbsp;Of&nbsp;&nbsp;Beautiful
            </h2>
          </div>
        </div>
        <div className="greeting-card">
          <div className="greeting-card-inner">
            Wild Iris Salon takes it's name from the Karmic essence translated
            to mean "immense concern for others." Our team has extensive
            knowledge and a great passion for hair while the salon creates a
            comfortable and relaxing experience for each guest.
            <br />
            <br />
            Our mission is to build loyal, lifelong relationships and to make
            sure everyone leaves with a smile and great hair.
            <br />
            <Link to="services">
              <div className="card-button">View Services</div>
            </Link>
          </div>
        </div>
        <div className="action-card-container">
          <Link to="/gallery">
            <div className="action-card gallery-card-m">
              <div className="card card-gallery">
                <div className="greeting-card-inner greeting-card-long">
                  Gallery
                </div>
              </div>
            </div>
          </Link>
          <Link to="/stylists">
            <div className="action-card stylists-card">
              <div className="card card-stylists">
                <div className="greeting-card-inner greeting-card-long">
                  Stylists
                </div>
              </div>
            </div>
          </Link>
          <Link to="/services">
            <div className="action-card services-card">
              <div className="card card-services">
                <div className="greeting-card-inner greeting-card-long">
                  Services
                </div>
              </div>
            </div>
          </Link>
          <Link to="/products">
            <div className="action-card products-card">
              <div className="card card-products">
                <div className="greeting-card-inner greeting-card-long">
                  Products
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="testimonial-container">
          <h1 id="testimonials">Testimonials</h1>

          {/* TESTIMONIAL 1 */}
          <div className="testimonial-card">
            <div className="testimonial-header">
              <img
                src={PATTY_TESTEMONIAL}
                className="testimonial-img"
                alt="testimonial card"
              />
              <p>Patty M.</p>
            </div>
            <div>
              The Wild Iris Salon consistently delivers five star service, the
              latest hair styling services combined with personalized service!
              The products and boutique services are all top line ......
              complimenting their outstanding delivery of services!
            </div>
          </div>

          {/* TESTIMONIAL 2 */}
          <div className="testimonial-card">
            <div className="testimonial-header">
              <img
                src={LOIS_TESTIMONIAL}
                className="testimonial-img"
                alt="testimonial card"
              />
              <p>Lois K.</p>
            </div>
            <div>
              I am so very fortunate to have Monica Busick as my stylist! She
              has been caring for my hair for more than 15 years which means she
              must have been in high school when we started 😉! Everyone in my
              family knows what Monica Hair means and NO ONE gets in the way of
              those appointments. Her professionalism knowledge and sheer talent
              are unsurpassed. While my hair requires full highlights and
              prevention of telltale outgrowth my daughter enjoys a subtle
              Balayage technique for that sun kissed look. We look forward to
              the color as well as the texture and healthy feel of Monica Hair.
              It is truly a pleasure to receive her expert care!
            </div>
          </div>

          {/* TESTIMONIAL 3 */}
          <div className="testimonial-card">
            <div className="testimonial-header">
              <img
                src={BLAKE_TESTIMONIAL}
                className="testimonial-img"
                alt="testimonial card"
              />
              <p>Blake G.</p>
            </div>
            <div>
              I was referred to the Wild Iris by Davines color line. Monica has
              been coloring and cutting my hair for 2 years. She always listens
              well and executes what I ask. She made great product
              recommendations and my hair has improved its health over the time
              I've seen her as a direct result. I trust she takes the same care
              and attention to detail with all her clients. Highly recommend her
              for color and naturally thinning hair.
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Home;
