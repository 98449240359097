import * as React from "react";
import * as RS from "rsuite";
import { SmallMessage } from "./Message";
import { PasswordTextField, TextField } from "./TextField";
import { login } from "../services/auth-service";
import { useNavigate } from "react-router-dom";
import "../styles/login.css";

const loginModel = RS.Schema.Model({
  username: RS.Schema.Types.StringType().isRequired("A username is required"),
  password: RS.Schema.Types.StringType().isRequired("A password is required"),
});

type LoginDetails = { username: string; password: string };

const Login: React.FC<{ onLogin: (success: boolean) => void }> = ({
  onLogin,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [loginDeatils, setLoginDetails] = React.useState<LoginDetails>({
    username: "",
    password: "",
  });
  const toaster = RS.useToaster();
  const navigate = useNavigate();

  const onSubmit = async (valid: boolean) => {
    if (!valid)
      return toaster.push(
        <SmallMessage message="Please fill out login form" type="error" />
      );
    setLoading(true);
    const loggedIn = await login(loginDeatils.username, loginDeatils.password);
    if (!loggedIn)
      toaster.push(<SmallMessage message="Invalid Credentials" type="error" />);
    setLoading(false);
    onLogin(loggedIn);
  };

  return (
    <RS.Container style={{ width: "100vw", height: "100vh" }}>
      <RS.Header>
        <RS.Navbar appearance="inverse" style={{ height: 75 }}>
          <RS.Navbar.Brand onClick={() => navigate("/admin")}>
            <h3 style={{ color: "white" }}>Wild Iris Salon - Login</h3>
          </RS.Navbar.Brand>
        </RS.Navbar>
      </RS.Header>
      <RS.Content className="login-content">
        <RS.Animation.Slide in>
          <RS.Panel header={<h3>Login</h3>} bordered className="login-panel">
            <RS.Form
              fluid
              model={loginModel}
              onSubmit={onSubmit}
              formValue={loginDeatils}
              onChange={(v) => setLoginDetails(v as LoginDetails)}
            >
              <TextField name="username" label="Username" />
              <PasswordTextField name="password" label="Password" />
              <RS.ButtonToolbar>
                <RS.Button
                  appearance="primary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Login
                </RS.Button>
              </RS.ButtonToolbar>
            </RS.Form>
            <RS.Button
              appearance="ghost"
              onClick={() => navigate("/")}
              style={{ marginTop: 10 }}
            >
              Go to Site Home Page
            </RS.Button>
          </RS.Panel>
        </RS.Animation.Slide>
      </RS.Content>
    </RS.Container>
  );
};

export default Login;
