import * as React from "react";
import Template from "../components/Template";
import { Link } from "react-router-dom";
import "../styles/contact.css";

const Contact: React.FC = () => {
  return (
    <Template>
      <h1 id="contact-title">Contact Us</h1>
      <div className="contact-bar">
        <div className="contact-card">
          <h2>General Contacts:</h2>
          <a href="tel:7208517799">720-851-7799</a>
          <a href="mailto:wildirissalon@gmail.com">wildirissalon@gmail.com</a>
        </div>
        <div className="contact-card">
          <h2>Visit Us:</h2>
          <a
            href="https://goo.gl/maps/4fCTQx7kfHzDSkXE9"
            target="_blank"
            rel="noreferrer"
          >
            19402 East Main Street, Suite B<br />
            Parker, CO 80138
          </a>
        </div>
        <div className="contact-card">
          <h2>Hours</h2>
          <p id="hour-text">
            M-F 8AM – 6PM
            <br />
            Sat 8AM – 4PM
            <br />
            Hours Vary by Stylist
          </p>
        </div>
      </div>
      <div className="stylist-contact">
        <Link to="/stylists">
          <div className="stylist-contact-btn">
            Click here to contact specific stylists
          </div>
        </Link>
      </div>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49239.88995078687!2d-104.82255530162588!3d39.525928396377175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c91fb3667a545%3A0x2a3d890d9903d458!2sWild%20Iris%20Salon%20Experience!5e0!3m2!1sen!2sus!4v1616024323211!5m2!1sen!2sus"
          style={{ border: 0 }}
          title="goolge map"
          loading="lazy"
        />
      </div>
    </Template>
  );
};

export default Contact;
