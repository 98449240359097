import * as React from "react";
import { Link } from "react-router-dom";
import LOGO from "../images/logo.png";

const Template: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const topNav = React.useRef<HTMLDivElement | null>(null);

  const path = window.location.pathname;

  const onHamburgerClick = () => {
    if (!topNav.current?.classList.contains("responsive"))
      topNav.current?.classList.add("responsive");
    else topNav.current?.classList.remove("responsive");
  };

  return (
    <React.Fragment>
      <div className="header">
        <Link to="/">
          <img src={LOGO} alt="logo" id="logo-header" />
        </Link>
        <h1 className="top-logo">Wild Iris Salon</h1>
        <h2 className="bottom-logo">
          Be&nbsp;&nbsp;Your&nbsp;&nbsp;Own&nbsp;&nbsp;Kind&nbsp;&nbsp;Of&nbsp;&nbsp;Beautiful
        </h2>
        <div className="topnav" ref={topNav}>
          <Link to="/" className={`nav-item ${path === "/" ? "active" : ""}`}>
            Home
          </Link>
          <Link
            to="/gallery"
            className={`nav-item ${path === "/gallery" ? "active" : ""}`}
          >
            Gallery
          </Link>
          <Link
            to="/services"
            className={`nav-item ${path === "/services" ? "active" : ""}`}
          >
            Services
          </Link>
          <Link
            to="/stylists"
            className={`nav-item ${path === "/stylists" ? "active" : ""}`}
          >
            Stylists
          </Link>
          <Link
            to="/products"
            className={`nav-item ${path === "/products" ? "active" : ""}`}
          >
            Products
          </Link>
          <Link
            to="/contact"
            className={`nav-item ${path === "/contact" ? "active" : ""}`}
          >
            Contact
          </Link>
          <a className="icon" href="#0" onClick={onHamburgerClick}>
            <i className="fa fa-bars" />
          </a>
        </div>
      </div>
      {children}
      <div className="footer">
        <div className="footer-card-container">
          <div className="footer-card">
            <h3>Contact</h3>
            <a href="tel:7208517799">720-851-7799</a>
            <a href="mailto:wildirissalon@gmail.com">wildirissalon@gmail.com</a>
          </div>
          <div className="footer-card">
            <h3>Address</h3>
            <a
              href="https://goo.gl/maps/4fCTQx7kfHzDSkXE9"
              target="_blank"
              rel="noreferrer"
            >
              19402 East Main Street, Suite B<br />
              Parker, CO 80138
            </a>
          </div>
          <div className="footer-card">
            <h3>Hours</h3>
            <p>
              M-F 8AM - 6PM
              <br />
              Sat 8AM - 4PM
              <br />
              Hours Vary by Stylist
            </p>
          </div>
        </div>
        <div className="media-bar">
          <a
            href="https://www.facebook.com/Wild-Iris-Salon-190408347648902/timeline/"
            target="_blank"
            rel="noreferrer"
            className="fa fa-facebook"
          >
            {" "}
          </a>
          {/* <a
            href="https://twitter.com/wildirissalon"
            target="_blank"
            rel="noreferrer"
            className="fa fa-twitter"
          >{" "}</a> */}
          <a
            href="https://www.instagram.com/wildirissalonexperience/"
            target="_blank"
            rel="noreferrer"
            className="fa fa-instagram"
          >
            {" "}
          </a>
        </div>
        <p className="copyright">
          &copy; Wild Iris Salon 2022 | All Rights Reserved
          <br />
          Copy or replication of content on
          <br />
          this site requires written permission.
        </p>
        <p className="credit">
          Site created by&nbsp;
          <a href="https://linktr.ee/cjcobb" target="_blank" rel="noreferrer">
            Carter Cobb
          </a>
        </p>
      </div>
    </React.Fragment>
  );
};

export default Template;
