import * as React from "react";
import Template from "../components/Template";
import CAT from "../images/404.gif";
import { Link } from "react-router-dom";
import "../styles/not-found.css";

const NotFound: React.FC = () => {
  return (
    <Template>
      <h1 id="not-found-title">Not Found :(</h1>
      <div className="not-found-container">
        <img src={CAT} alt="404 not found" />
        <br />
        <br />
        <h3 className="explanation">
          The page you are looking for does not exist. Perhaps it was stolen by
          Muffins the cat.
        </h3>
        <Link to="/">
          <div className="home-btn" style={{ background: "black" }}>
            Return Home
          </div>
        </Link>
      </div>
    </Template>
  );
};

export default NotFound;
