import * as React from "react";
import Template from "../components/Template";
import { getGalleryKeys } from "../services/gallery-service";
import Loader from "../components/Loader";
import "../styles/gallery.css";

const Gallery: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [galleryKeys, setGalleryKeys] = React.useState<string[]>([]);

  React.useEffect(() => {
    getGalleryKeys()
      .then(setGalleryKeys)
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <Template>
        <Loader />
      </Template>
    );

  return (
    <Template>
      <div>
        <h1 id="gallery-title">Gallery</h1>
        <div className="gallery-container">
          {galleryKeys.map((key, i) => (
            <a
              key={i}
              href={`${process.env.REACT_APP_S3_DOMAIN}/${key}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="gallery-card"
                alt="gallery item"
                src={`${process.env.REACT_APP_S3_DOMAIN}/${key}`}
              />
            </a>
          ))}
        </div>
      </div>
    </Template>
  );
};

export default Gallery;
