import * as React from "react";
import * as RS from "rsuite";
import Loader from "./Loader";
import { Stylist } from "../types/stylist";
import {
  deleteStylistById,
  getAllStylists,
} from "../services/stylists-service";
import { SmallMessage } from "./Message";
import StylistForm from "./StylistForm";
import Uploader from "./Uploader";
import { v4 as uuid } from "uuid";
import { getStylistGalleryKeys } from "../services/gallery-service";
import { deleteImage } from "../services/file-service";

const StylistsAdmin: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [stylists, setStylists] = React.useState<Stylist[]>([]);
  const [deleting, setDeleting] = React.useState(false);
  const [openNew, setOpenNew] = React.useState(false);
  const [editStylist, setEditStylist] = React.useState<Stylist | null>(null);
  const [stylistGalleryKey, setStylistGalleryKey] = React.useState<
    string | null
  >(null);
  const [stylistGalleryKeys, setStylistGalleryKeys] = React.useState<string[]>(
    []
  );
  const [loadingStylistGallery, setLoadingStylistGallery] =
    React.useState(false);
  const toaster = RS.useToaster();

  React.useEffect(() => {
    getAllStylists()
      .then((s) => setStylists(s.sort((a, b) => a.display - b.display)))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  const onDelete = async (id: string) => {
    setDeleting(true);
    const deleted = await deleteStylistById(id);
    if (deleted) {
      const st = await getAllStylists();
      setStylists(st);
      toaster.push(<SmallMessage message="Deleted" type="success" />);
    } else
      toaster.push(
        <SmallMessage message="Failed ot delete stylist" type="error" />
      );
    setDeleting(false);
  };

  const onDeleteStylistGalleryImage = async (key: string) => {
    setLoadingStylistGallery(true);
    const deleted = await deleteImage(key);
    if (deleted) {
      const keys = stylistGalleryKeys.filter((x) => x !== key);
      setStylistGalleryKeys(keys);
      toaster.push(<SmallMessage message="Deleted Image" type="success" />);
    } else
      toaster.push(
        <SmallMessage message="Failed to Delete Image" type="error" />
      );
    setLoadingStylistGallery(false);
  };

  return (
    <div style={{ padding: 10 }}>
      <RS.Button appearance="primary" onClick={() => setOpenNew(true)}>
        Create New Stylist
      </RS.Button>
      <RS.List style={{ marginTop: 10 }}>
        {stylists.map((stylist, i) => (
          <RS.List.Item key={i}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: 5,
              }}
            >
              <img
                alt="stylist"
                style={{ height: 100, width: 100, objectFit: "contain" }}
                src={`${process.env.REACT_APP_S3_DOMAIN}/stylists/${
                  stylist.stylistId
                }?_v=${Math.random().toString(36).slice(2)}`}
              />
              <div style={{ padding: 10 }}>
                <h3>{stylist.name}</h3>
                <p>{stylist.bio}</p>
              </div>
            </div>
            <RS.ButtonToolbar style={{ margin: "5px 10px" }}>
              <RS.Button
                appearance="primary"
                onClick={() => setEditStylist(stylist)}
              >
                Edit
              </RS.Button>
              <RS.Button
                appearance="ghost"
                onClick={() => {
                  setStylistGalleryKey(
                    `stylist-gallery/${stylist.stylistId}/${uuid()}`
                  );
                  setLoadingStylistGallery(true);
                  getStylistGalleryKeys(stylist.stylistId)
                    .then((keys) => setStylistGalleryKeys(keys))
                    .catch(console.log)
                    .finally(() => setLoadingStylistGallery(false));
                }}
              >
                Upload to Gallery
              </RS.Button>
              <RS.Button
                onClick={() => onDelete(stylist.stylistId)}
                style={{
                  background: "red",
                  color: "white",
                }}
                loading={deleting}
                disabled={deleting}
              >
                Delete
              </RS.Button>
            </RS.ButtonToolbar>
          </RS.List.Item>
        ))}
      </RS.List>
      <RS.Drawer
        open={openNew}
        onClose={() => setOpenNew(false)}
        backdrop
        style={{ maxWidth: "100%" }}
      >
        <RS.Drawer.Header>
          <RS.Drawer.Title>Create New Stylist</RS.Drawer.Title>
        </RS.Drawer.Header>
        <RS.Drawer.Body style={{ padding: "10px 10px 10px 20px" }}>
          <StylistForm
            display={stylists.length + 1}
            onSaved={() => setOpenNew(false)}
          />
        </RS.Drawer.Body>
      </RS.Drawer>
      {editStylist !== null && (
        <RS.Drawer
          open={editStylist !== null}
          onClose={() => setEditStylist(null)}
          backdrop
          style={{ maxWidth: "100%" }}
        >
          <RS.Drawer.Header>
            <RS.Drawer.Title>Edit {editStylist.name}:</RS.Drawer.Title>
          </RS.Drawer.Header>
          <RS.Drawer.Body style={{ padding: "10px 10px 10px 20px" }}>
            <StylistForm
              stylist={editStylist}
              display={editStylist.display}
              onSaved={() =>
                getAllStylists()
                  .then((s) => {
                    setStylists(s.sort((a, b) => a.display - b.display));
                    setEditStylist(null);
                  })
                  .catch(console.log)
              }
            />
          </RS.Drawer.Body>
        </RS.Drawer>
      )}
      {stylistGalleryKey !== null && (
        <RS.Modal
          open={stylistGalleryKey !== null}
          onClose={() => setStylistGalleryKey(null)}
        >
          <RS.Modal.Header>
            <RS.Modal.Title>Quick add image to stylist gallery:</RS.Modal.Title>
          </RS.Modal.Header>
          <RS.Modal.Body>
            <Uploader
              bucketKey={stylistGalleryKey}
              draggable
              buttonText="Upload"
              onDone={() => {
                setStylistGalleryKeys((s) => [stylistGalleryKey, ...s]);
                setStylistGalleryKey(null);
              }}
            />
            <div style={{ maxHeight: 450 }}>
              {loadingStylistGallery ? (
                <Loader />
              ) : (
                <RS.List style={{ marginTop: 10 }}>
                  {stylistGalleryKeys.map((key, i) => (
                    <RS.List.Item key={i}>
                      <img
                        alt="gallery item"
                        style={{
                          height: 100,
                          width: 100,
                          objectFit: "contain",
                        }}
                        src={`${process.env.REACT_APP_S3_DOMAIN}/${key}`}
                      />
                      <a
                        href={`${process.env.REACT_APP_S3_DOMAIN}/${key}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ margin: "0px 20px" }}
                      >
                        {key.replace(/^stylist-gallery\/[a-f0-9\-]{36}\//, "")}
                      </a>
                      <RS.ButtonToolbar style={{ margin: "5px 10px" }}>
                        <RS.Button
                          style={{
                            background: "red",
                            color: "white",
                          }}
                          appearance="default"
                          onClick={() => onDeleteStylistGalleryImage(key)}
                          loading={deleting}
                          disabled={deleting}
                        >
                          Delete
                        </RS.Button>
                      </RS.ButtonToolbar>
                    </RS.List.Item>
                  ))}
                </RS.List>
              )}
            </div>
          </RS.Modal.Body>
        </RS.Modal>
      )}
    </div>
  );
};

export default StylistsAdmin;
