import * as React from "react";
import "../styles/loading.css";

const Loader: React.FC<{ full?: boolean }> = ({ full }) => {
  return (
    <div className={full ? "full-container" : "container"}>
      <div className="loader"></div>
      <h2>Loading...</h2>
    </div>
  );
};

export default Loader;
