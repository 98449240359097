import * as React from "react";
import * as RS from "rsuite";
import { reLogin } from "../services/auth-service";
import Loader from "../components/Loader";
import Login from "../components/Login";
import { useNavigate } from "react-router-dom";
import {
  DoubleRightOutlined,
  DoubleLeftOutlined,
  TeamOutlined,
  PictureOutlined,
  AppstoreOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import "../styles/admin.css";
import GalleryAdmin from "../components/GalleryAdmin";
import ProductsAdmin from "../components/ProductsAdmin";
import StylistsAdmin from "../components/StylistsAdmin";

const iconStyles: React.CSSProperties = {
  fontSize: 22,
  position: "absolute",
  height: 22,
  left: 16,
  lineHeight: 1.25,
  marginRight: 20,
};

const Admin: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [expanded, setExpanded] = React.useState(window.innerWidth >= 800);
  const [activeKey, setActiveKey] = React.useState("stylists");
  const sidebarRef = React.createRef<HTMLElement>();
  const navigate = useNavigate();

  React.useEffect(() => {
    reLogin()
      .then(setLoggedIn)
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader full />;

  if (!loggedIn) return <Login onLogin={(s) => setLoggedIn(s)} />;

  const logout = () => {
    localStorage.removeItem("auth");
    setLoggedIn(false);
  };

  return (
    <RS.Container style={{ width: "100vw", height: "100vh" }}>
      <RS.Navbar appearance="inverse" style={{ height: 70 }}>
        <RS.Navbar.Brand onClick={() => navigate("/admin")}>
          <h4 style={{color: "white"}}>Wild Iris Salon - Admin Portal</h4>
        </RS.Navbar.Brand>
      </RS.Navbar>
      <RS.Container>
        <RS.Sidebar
          className="template-sidebar"
          ref={sidebarRef}
          width={expanded ? 260 : 56}
          collapsible
        >
          <RS.Sidenav
            expanded={expanded}
            appearance="subtle"
            style={{
              height: 4 * 52,
            }}
          >
            <RS.Sidenav.Body>
              <RS.Nav onSelect={setActiveKey}>
                <RS.Nav.Item
                  eventKey="stylists"
                  active={activeKey === "stylists"}
                  style={{
                    borderRight:
                      activeKey === "stylists"
                        ? `8px solid var(--rs-navbar-inverse-bg)`
                        : undefined,
                  }}
                  icon={<TeamOutlined style={iconStyles} />}
                >
                  Stylists
                </RS.Nav.Item>
                <RS.Nav.Item
                  eventKey="gallery"
                  active={activeKey === "gallery"}
                  style={{
                    borderRight:
                      activeKey === "gallery"
                        ? `8px solid var(--rs-navbar-inverse-bg)`
                        : undefined,
                  }}
                  icon={<PictureOutlined style={iconStyles} />}
                >
                  Gallery
                </RS.Nav.Item>
                <RS.Nav.Item
                  eventKey="products"
                  active={activeKey === "products"}
                  style={{
                    borderRight:
                      activeKey === "products"
                        ? `8px solid var(--rs-navbar-inverse-bg)`
                        : undefined,
                  }}
                  icon={<AppstoreOutlined style={iconStyles} />}
                >
                  Products
                </RS.Nav.Item>
                <RS.Nav.Item
                  eventKey="logout"
                  icon={<KeyOutlined style={iconStyles} />}
                  onClick={logout}
                >
                  Logout
                </RS.Nav.Item>
              </RS.Nav>
            </RS.Sidenav.Body>
          </RS.Sidenav>
          <RS.Navbar appearance="subtle">
            <RS.Nav style={{ width: "100%" }}>
              <RS.Button
                appearance="primary"
                onClick={() => setExpanded(!expanded)}
                className="template-collapse-btn"
              >
                {expanded ? (
                  <DoubleLeftOutlined style={{ fontSize: 25 }} />
                ) : (
                  <DoubleRightOutlined style={{ fontSize: 25 }} />
                )}
              </RS.Button>
            </RS.Nav>
          </RS.Navbar>
        </RS.Sidebar>
        <RS.Content>
          {activeKey === "stylists" && <StylistsAdmin />}
          {activeKey === "gallery" && <GalleryAdmin />}
          {activeKey === "products" && <ProductsAdmin />}
        </RS.Content>
      </RS.Container>
      <RS.Footer></RS.Footer>
    </RS.Container>
  );
};

export default Admin;
