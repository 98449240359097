import { $http, $httpS3Raw } from "../axios";

export const getPresingedURL = async (
  mimetype: string,
  filename: string
): Promise<string> => {
  try {
    const auth = localStorage.getItem("auth");
    if (!auth) return "";
    const response = await $http.get(
      `/admin/presign?mimetype=${encodeURIComponent(
        mimetype
      )}&filename=${encodeURIComponent(filename)}`,
      {
        headers: { Authorization: `Basic ${auth}` },
      }
    );
    return response.data?.url;
  } catch {
    return "";
  }
};

export const uploadFile = async (
  mimetype: string,
  file: Buffer | File,
  url: string
): Promise<boolean> => {
  try {
    await $httpS3Raw.put(url, file, { headers: { "Content-Type": mimetype } });
    return true;
  } catch {
    return false;
  }
};

export const deleteImage = async (key: string): Promise<boolean> => {
  try {
    const auth = localStorage.getItem("auth");
    if (!auth) return false;
    await $http.delete(`/admin/image?key=${encodeURIComponent(key)}`, {
      headers: { Authorization: `Basic ${auth}` },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
