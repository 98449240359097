import { $http } from "../axios";

export const getGalleryKeys = async (): Promise<string[]> => {
  try {
    const response = await $http.get("/gallery/keys");
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getStylistGalleryKeys = async (id: string): Promise<string[]> => {
  try {
    const response = await $http.get(`/stylist/${id}/gallery/keys`);
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
