import { $http } from "../axios";
import { Buffer } from "buffer";

export const login = async (
  username: string,
  password: string
): Promise<boolean> => {
  try {
    const Authorization =
      "Basic " + Buffer.from(`${username}:${password}`).toString("base64");
    const response = await $http.post("/admin/login", null, {
      headers: { Authorization },
    });
    const authed = response.data.auth || false;
    if (authed) localStorage.setItem("auth", Authorization);
    return authed;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const reLogin = async (): Promise<boolean> => {
  try {
    const Authorization = localStorage.getItem("auth");
    const response = await $http.post("/admin/login", null, {
      headers: { Authorization },
    });
    return response.data.auth || false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
