import axios from "axios";

export const $http = axios.create({
  baseURL: process.env.REACT_APP_HTTP_API_DOMAIN,
  headers: { "Content-Type": "application/json" },
});

export const $httpS3Raw = axios.create({
  baseURL: process.env.REACT_APP_S3_DOMAIN,
});
