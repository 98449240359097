import * as React from "react";
import Template from "../components/Template";
import { getProductKeys } from "../services/products-service";
import Loader from "../components/Loader";
import "../styles/products.css";

const Products: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [productKeys, setProductKeys] = React.useState<string[]>([]);

  React.useEffect(() => {
    getProductKeys()
      .then(setProductKeys)
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <Template>
        <Loader />
      </Template>
    );

  return (
    <Template>
      <h1 id="products-title">Products</h1>
      <div className="product-container">
        {productKeys.map((key, i) => (
          <img
            key={i}
            className="product-img"
            alt="product"
            src={`${process.env.REACT_APP_S3_DOMAIN}/${key}`}
          />
        ))}
      </div>
    </Template>
  );
};

export default Products;
