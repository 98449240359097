import { $http } from "../axios";
import { Stylist } from "../types/stylist";

export const getAllStylists = async (): Promise<Stylist[]> => {
  try {
    const response = await $http.get("/stylists");
    return ((response.data || []) as Stylist[]).sort(
      (a, b) => a.display - b.display
    );
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getStylistById = async (id: string): Promise<Stylist | null> => {
  try {
    const response = await $http.get(`/stylist/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const saveStylist = async (stylist: Stylist): Promise<boolean> => {
  try {
    const auth = localStorage.getItem("auth");
    if (!auth) return false;
    await $http.post(`/stylist/${stylist.stylistId}`, JSON.stringify(stylist), {
      headers: { Authorization: `Basic ${auth}` },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteStylistById = async (id: string): Promise<boolean> => {
  try {
    const auth = localStorage.getItem("auth");
    if (!auth) return false;
    await $http.delete(`/stylist/${id}`, {
      headers: { Authorization: `Basic ${auth}` },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
