import * as React from "react";
import * as RS from "rsuite";
import { getGalleryKeys } from "../services/gallery-service";
import Uploader from "./Uploader";
import { v4 as uuid } from "uuid";
import { deleteImage } from "../services/file-service";
import { SmallMessage } from "./Message";
import Loader from "./Loader";

const GalleryAdmin: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [galleryKeys, setGalleryKeys] = React.useState<string[]>([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const toaster = RS.useToaster();

  React.useEffect(() => {
    getGalleryKeys()
      .then(setGalleryKeys)
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  const onUpload = async () => {
    const keys = await getGalleryKeys();
    setGalleryKeys(keys);
    setOpenDrawer(false);
  };

  const onDelete = async (key: string) => {
    setDeleting(true);
    const deleted = await deleteImage(key);
    if (deleted) {
      const keys = await getGalleryKeys();
      setGalleryKeys(keys);
      toaster.push(<SmallMessage message="Deleted" type="success" />);
    } else
      toaster.push(<SmallMessage message="Failed to Delete" type="error" />);
    setDeleting(false);
  };

  return (
    <div style={{ padding: 10 }}>
      <RS.Button appearance="primary" onClick={() => setOpenDrawer(true)}>
        Upload New Gallery Image
      </RS.Button>
      <RS.List style={{ marginTop: 10 }}>
        {galleryKeys.map((key, i) => (
          <RS.List.Item key={i}>
            <img
              alt="gallery item"
              style={{ height: 100, width: 100, objectFit: "contain" }}
              src={`${process.env.REACT_APP_S3_DOMAIN}/${key}`}
            />
            <a
              href={`${process.env.REACT_APP_S3_DOMAIN}/${key}`}
              target="_blank"
              rel="noreferrer"
              style={{ margin: "0px 20px" }}
            >
              {key.replace("gallery/", "")}
            </a>
            <RS.ButtonToolbar style={{ margin: "5px 10px" }}>
              <RS.Button
                style={{
                  background: "red",
                  color: "white",
                }}
                appearance="default"
                onClick={() => onDelete(key)}
                loading={deleting}
                disabled={deleting}
              >
                Delete
              </RS.Button>
            </RS.ButtonToolbar>
          </RS.List.Item>
        ))}
      </RS.List>
      <RS.Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        style={{ maxWidth: "100%" }}
        backdrop
      >
        <RS.Drawer.Header>
          <RS.Drawer.Title>Upload Gallery Image:</RS.Drawer.Title>
        </RS.Drawer.Header>
        <RS.Drawer.Body style={{ padding: "10px 10px 10px 20px" }}>
          <Uploader
            bucketKey={`gallery/${uuid()}`}
            draggable
            buttonText="Upload Gallery image"
            onDone={onUpload}
          />
        </RS.Drawer.Body>
      </RS.Drawer>
    </div>
  );
};

export default GalleryAdmin;
