import * as React from "react";
import Template from "../components/Template";
import { Link } from "react-router-dom";
import { Stylist } from "../types/stylist";
import { getAllStylists } from "../services/stylists-service";
import Loader from "../components/Loader";
import "../styles/stylists.css";

const Stylists: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [stylists, setStylists] = React.useState<Stylist[]>([]);

  React.useEffect(() => {
    getAllStylists()
      .then(setStylists)
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <Template>
        <Loader />
      </Template>
    );

  return (
    <Template>
      <h1 id="stylists-title">Our Team of Stylists</h1>
      <div className="stylist-card-container-v2">
        {stylists.map((stylist, i) => (
          <div key={i} className="stylist-card-v2">
            <img
              src={`${process.env.REACT_APP_S3_DOMAIN}/stylists/${
                stylist.stylistId
              }?_v=${Math.random().toString(36).slice(2)}`}
              alt="stylist"
              className="stylist-card-img-v2"
            />
            <div className="stylist-card-info-container-v2">
              {/* <h2>{stylist.name}</h2>
              <p>{stylist.bio}</p>
              <div className="stylist-specialty-container">
                {stylist.specialized?.map((item, j) => (
                  <div key={j} className="stylist-specialty">
                    {item}
                  </div>
                ))}
              </div> */}
              <Link to={`/stylist/${stylist.stylistId}`}>
                <div className="stylist-card-more">
                  Learn more about {stylist.name} &rarr;
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Template>
  );
};

export default Stylists;
