import * as React from "react";
import * as RS from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import { SmallMessage } from "./Message";
import { getPresingedURL, uploadFile } from "../services/file-service";

export interface UploaderProps {
  bucketKey: string;
  draggable?: boolean;
  buttonProps?: RS.ButtonProps;
  buttonText?: string;
  onDone?: () => void;
}

const Uploader: React.FC<UploaderProps> = ({
  bucketKey,
  buttonProps,
  draggable,
  buttonText,
  onDone,
}) => {
  const [file, setFile] = React.useState<FileType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const toaster = RS.useToaster();

  const onSave = async () => {
    setLoading(true);
    const data = file[0].blobFile;
    if (data) {
      const presingedURL = await getPresingedURL(data.type, bucketKey);
      if (presingedURL) {
        const uploaded = await uploadFile(data.type, data, presingedURL);
        if (uploaded)
          toaster.push(<SmallMessage type="success" message="Uploaded!" />);
        else
          toaster.push(
            <SmallMessage type="error" message="Failed to upload file" />
          );
      } else
        toaster.push(
          <SmallMessage type="error" message="Failed to upload file" />
        );
    } else
      toaster.push(
        <SmallMessage type="error" message="Failed to upload file" />
      );
    setLoading(false);
    onDone?.();
  };

  return (
    <div>
      <span>{buttonText || "Upload File"}:</span>
      <RS.Uploader
        action=""
        accept="image/*"
        autoUpload={false}
        draggable={draggable}
        fileList={file}
        disableMultipart
        onChange={(fileList) => {
          const file = fileList.at(-1);
          file && setFile([file]);
        }}
        listType="picture-text"
        style={{ marginBottom: 10 }}
        onRemove={() => setFile([])}
      >
        {draggable && (
          <div
            style={{
              height: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>
              Click or Drag files to this area to {buttonText || "Upload File"}
            </span>
          </div>
        )}
      </RS.Uploader>
      <RS.Button
        appearance="primary"
        {...buttonProps}
        style={{ marginBottom: 10, ...buttonProps?.style }}
        loading={loading}
        disabled={loading || !file.length}
        onClick={onSave}
      >
        {buttonText || "Save File"}
      </RS.Button>
    </div>
  );
};

export default Uploader;
